import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { getCompoundSentenceList } from '@format/list';
import { Button, LinkButton } from '@ui/button';
import { PromoPill, ResponsiveImage } from '@web/molecules';
import { ErrorText } from '@web/atoms';
import { useReporter } from '../../reporter';
import boxImage from '../../images/onboarding/box.svg';
import { confirmClicked } from './events';
import {
  Discount,
  StyledBoxSummary,
  Headline,
  ImageContainer,
  RecipesInfoContainer,
  RecipesInfoLabel,
  SelectedRecipes,
  PacksContainer,
  Packs,
  SelectedPacks,
  MealPrepTypeContainer,
  MealPrepTypeLabel,
  SelectedMealPrepType,
  Checkout,
  CheckoutInfo,
  SummaryInfo,
  StyledTotalAmount,
  StyledAmount,
  Top,
  ErrorLink,
} from './styles.js';

export const BoxSummary = ({
  headline,
  recipes,
  mealPrepText,
  totalPacks,
  totalBags,
  periodAmount,
  discountedPeriodAmount,
  period,
  button,
  error,
  offerCategory,
  bakedUnavailable,
  weeksOfFood,
  checkoutUrl = '',
  isLegacyCustomer = true,
}) => {
  const reporter = useReporter();
  const trackEvent = confirmClicked();
  const onConfirmClick = event => {
    button?.onClick?.(event);
    return reporter.tag(trackEvent);
  };

  const freshRecipes = capitalize(getCompoundSentenceList(recipes.fresh));
  const bakedRecipes = capitalize(getCompoundSentenceList(recipes.dry));

  return (
    <StyledBoxSummary errorBorder={error}>
      <Top>
        <ImageContainer>
          <ResponsiveImage
            src={boxImage.src}
            alt="Ollie box"
            width={boxImage.width}
            height={boxImage.height}
          />
        </ImageContainer>
        <SummaryInfo>
          <Headline>
            {weeksOfFood}
            {headline}
          </Headline>
          <Discount>
            <PromoPill
              message="discount applied"
              variant="dark"
              category={offerCategory}
            />
          </Discount>
          <MealPrepTypeContainer>
            <MealPrepTypeLabel>Plan type:</MealPrepTypeLabel>
            <SelectedMealPrepType>{mealPrepText}</SelectedMealPrepType>
          </MealPrepTypeContainer>
          {Boolean(freshRecipes.length) && (
            <RecipesInfoContainer>
              <RecipesInfoLabel>Fresh Recipes:</RecipesInfoLabel>
              <SelectedRecipes>{freshRecipes}</SelectedRecipes>
            </RecipesInfoContainer>
          )}
          {Boolean(bakedRecipes.length) && (
            <RecipesInfoContainer>
              <RecipesInfoLabel>Baked Recipes:</RecipesInfoLabel>
              <SelectedRecipes>{bakedRecipes}</SelectedRecipes>
            </RecipesInfoContainer>
          )}
          <PacksContainer>
            <Packs>No. of items:</Packs>
            <SelectedPacks>
              {totalPacks ? totalPacks + ' fresh packs' : ''}
              {totalPacks && totalBags ? ', ' : ''}{' '}
              {totalBags ? totalBags + ' baked bags' : ''}
            </SelectedPacks>
          </PacksContainer>
        </SummaryInfo>
      </Top>
      <Checkout>
        {discountedPeriodAmount !== 'FREE' ? (
          <CheckoutInfo>
            {!!discountedPeriodAmount &&
              periodAmount !== discountedPeriodAmount && (
                <StyledTotalAmount>{periodAmount}</StyledTotalAmount>
              )}
            <StyledAmount>
              {discountedPeriodAmount ?? periodAmount}/{period}
            </StyledAmount>
          </CheckoutInfo>
        ) : (
          <CheckoutInfo>
            <StyledAmount>{discountedPeriodAmount}</StyledAmount>
          </CheckoutInfo>
        )}
        {isLegacyCustomer ? (
          <Button
            onClick={onConfirmClick}
            disabled={button.disabled}
            isLoading={button.loading}
            ref={button.ref}
            horizontalPadding={12}
            verticalPadding={6}
            variant="spinach"
          >
            {button.ctaText}
          </Button>
        ) : (
          <LinkButton href={checkoutUrl} fullWidth variant="spinach">
            {button.ctaText}
          </LinkButton>
        )}
      </Checkout>
      {error != null && (
        <ErrorText>
          {error.message}
          <ErrorLink href={error.link}>{error.linkText}</ErrorLink>
        </ErrorText>
      )}
      {bakedUnavailable && (
        <ErrorText>
          Our baked plans are currently unavailable. Please select a fresh or
          mixed plan to reactivate.
        </ErrorText>
      )}
    </StyledBoxSummary>
  );
};

BoxSummary.displayName = 'BoxSummary';

BoxSummary.propTypes = {
  headline: PropTypes.string.isRequired,
  mealPrepText: PropTypes.string.isRequired,
  recipes: PropTypes.shape({
    fresh: PropTypes.arrayOf(PropTypes.string.isRequired),
    dry: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  totalPacks: PropTypes.number.isRequired,
  totalBags: PropTypes.number.isRequired,
  periodAmount: PropTypes.string.isRequired,
  discountedPeriodAmount: PropTypes.string,
  period: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
  }),
  button: PropTypes.shape({
    ref: PropTypes.any,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    ctaText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }),
  offerCategory: PropTypes.string,
  bakedUnavailable: PropTypes.bool,
  weeksOfFood: PropTypes.number.isRequired,
  checkoutUrl: PropTypes.string,
  isLegacyCustomer: PropTypes.bool.isRequired,
};
